// Typography

$fontSizeBase: 15px;
$fontSizeBaseMobile: 14px;

$fontStackHeader: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$fontStackBody: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

// Colours

$colorHFGrey: #e2e2e2;
$colorHFBlue: #8797b6;
$colorHFYellow: #ffff00;
$colorHFBeige: #a28b6d;

$colorBackground: white;

$colorFontHeader: #111;
$colorFontBody: #111;
